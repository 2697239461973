<template>
    <v-container>
        <base-section-heading
        title="about.models.title">
        </base-section-heading>
        
        <AlertExternal
        :introSeeAlso="$t('about.models.alert.text')"
        :seeAlso="$t('about.models.alert.seeAlso')"
        :link="$t('about.models.alert.link')"
        ></AlertExternal>
        
        <SectionWithImage
        v-for="section in sections"
        :key="section.key"
        :id="section.id"
        :title="section.title"
        :text="section.text"
        :imageName="section.imageName"
        :captionText="section.captionText"
        :altText="section.altText"
        >
        </SectionWithImage>
        
    </v-container>
</template>

<script>
import SectionWithImage from '@/components/docs/SectionWithImage';
import AlertExternal from '@/components/base/AlertExternal';

export default {
    components: {
      SectionWithImage,
      AlertExternal
    },
    data() {
        return {
            sections: [
                { 
                    key: 1,
                    id: 'rdf-section',    
                    title: 'about.models.section-1.title',
                    text: 'about.models.section-1.text',
                    imageName: 'about.models.section-1.imageName',
                    captionText: 'about.models.section-1.captionText',
                    altText: 'about.models.section-1.altText'
                },
                { 
                    key: 2, 
                    id: 'frbr-section',
                    title: 'about.models.section-2.title',
                    text: 'about.models.section-2.text',
                    imageName: 'about.models.section-2.imageName',
                    captionText: 'about.models.section-2.captionText',
                    altText: 'about.models.section-2.altText'
                },
                { 
                    key: 3, 
                    id: 'voc-section',
                    title: 'about.models.section-3.title',
                    text: 'about.models.section-3.text',
                    imageName: 'about.models.section-3.imageName',
                    captionText: 'about.models.section-3.captionText',
                    altText: 'about.models.section-3.altText'
                },
                { 
                    key: 4, 
                    id: 'ontology-section',
                    title: 'about.models.section-4.title',
                    text: 'about.models.section-4.text',
                    imageName: 'about.models.section-4.imageName',
                    captionText: 'about.models.section-4.captionText',
                    altText: 'about.models.section-4.altText'
                },
                { 
                    key: 5, 
                    id: 'document-section',
                    title: 'about.models.section-5.title',
                    text: 'about.models.section-5.text',
                    imageName: 'about.models.section-5.imageName',
                    captionText: 'about.models.section-5.captionText',
                    altText: 'about.models.section-5.altText'
                },
                { 
                    key: 6, 
                    id: 'metadata-section',
                    title: 'about.models.section-6.title',
                    text: 'about.models.section-6.text',
                    imageName: 'about.models.section-6.imageName',
                    captionText: 'about.models.section-6.captionText',
                    altText: 'about.models.section-6.altText'
                }
            ],
        }
    }
}
</script>

<style scoped>
a:hover {
    background-color: lightyellow ;
    text-decoration: none
}
div >>> a:hover {
    background-color: lightyellow ;
    text-decoration: none
}
</style>
